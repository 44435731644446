<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle
          title="Listado de archivos"
          subtitle="Solo se puede utilizar los siguientes formatos: jpg, jpeg, png, gif"
        />
      </v-flex>
      <v-flex xs12>
        <v-card>
          <v-card-text>
            <FilePond
              ref="pond"
              class="file-pond"
              name="image"
              label-idle='Arrastra y suelta tus archivos o <span class="filepond--label-action"> Buscalos </span>'
              allow-multiple="true"
              accepted-file-types="image/jpg, image/jpeg, image/png, image/gif"
              :server="{ process }"
              data-max-file-size="2MB"
              data-max-files="1"
              :files="myFiles"
              @error="onError"
            />
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :headers="headers"
          :items="files"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          class="elevation-1"
          disable-initial-sort
        >
          <template v-slot:items="props">
            <td>
              <a :href="`https://storage.googleapis.com/rosalinda-imagenes/${props.item.url}`" target="_blank">
                {{ props.item.name }}
              </a>
            </td>
            <td class="text-xs-center">
              {{ props.item.contentType }}
            </td>
            <td class="text-xs-center">
              <span>{{ props.item.size }}</span>
            </td>
            <td class="text-xs-right">
              <v-icon
                class="mr-4"
                @click="copyLink(`https://storage.googleapis.com/rosalinda-imagenes/${props.item.url}`)"
              >
                link
              </v-icon>
              <v-icon @click="deleteHandle(props.item.url, props.item.name)">
                delete
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

import { GET_FILES } from '../../config'
import AppTitle from '../useful/title.vue'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

export default {
  name: 'SettingsFiles',
  components: {
    AppTitle,
    FilePond
  },
  data() {
    return {
      myFiles: [],
      files: [],
      headers: [
        { text: 'Archivo', align: 'left', value: 'name', sortable: true },
        { text: 'Formato', align: 'center', sortable: false },
        { text: 'Tamaño', align: 'center', sortable: false },
        { text: '', align: 'left', sortable: false }
      ],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    async get() {
      try {
        const res = await this.$http.get(GET_FILES)
        this.files = this._.orderBy(res.data, ['updated', 'name'], ['desc', 'asc'])
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async process(fieldName, file, metadata, load, error, progress, abort) {
      const formData = new FormData()
      formData.append(fieldName, file)
      if (file.size < 2000000) {
        console.log(this.detail)
        try {
          await this.$http.post(GET_FILES, formData, {
            headers: { 'content-type': 'multipart/form-data' }
          })
          this.get()
          load('success')
        } catch (e) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error al subir el archivo'
          })
          error()
        }
      } else {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'El tamaño maximo de archivo es de 1MB'
        })
        error()
      }
      return {
        abort: () => {
          // Let FilePond know the request has been cancelled
          abort()
        }
      }
    },
    async deleteHandle(url, name) {
      if (confirm(`Quieres Eliminar ${name}`)) {
        try {
          await this.$http.put(GET_FILES, { name: url })
          this.get()
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    copyLink(url) {
      this.$copyText(url).then(e => {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Copiado con exito!'
        })
      })
    },
    onError(error, file) {
      console.log(error, file)
    }
  }
}
</script>

<style>
.file-pond .filepond--root {
  margin-bottom: 0 !important;
}
</style>
